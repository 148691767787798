const API_BASE = "http://ilmiodog.ddns.net";
let tmdbConfig;
let baseImageUrl;
const basePosterSize = "w185";

// const defaultFetchParams = {
//   headers: {
//     "Content-Type": "application/json",
//     Authorization:
//       "Bearer " +
//       "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI2Mjg3MmY5MGQwZjAzMmQ3Nzc2MmE0OGU5NWQ3NjcwZCIsInN1YiI6IjY2MmEzZmM4ZDRkNTA5MDBiYWUxZDZjYiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.F8VWSVQd4c04xWhCAu1Cai3Vg9HM2-3j5tX9NBS4RcY",
//   },
// };

export function getImageUrl(path: string, posterSize: string = basePosterSize) {
  return baseImageUrl + posterSize + path;
}

export function getImageUrlAbsolute(path: string = basePosterSize) {
  return path;
}

// function get(path: string, params: RequestInit = {}) {
//   if (tmdbConfig) {
//     return _get(path, params);
//   } else {
//     return loadConfig().then(() => _get(path, params));
//   }
// }

function _get(path: string, params: RequestInit = {}) {
  return fetch(API_BASE + path, {
    // ...defaultFetchParams,
    ...params,
  }).then((r) => r.json());
}

// function loadConfig() {
//   return _get("/configuration").then((data) => {
//     tmdbConfig = data;
//     baseImageUrl = data.images?.secure_base_url;
//     return data;
//   });
// }

export default {
  _get,
};
