import api from "..";
import {
  convertItemsToTiles,
  chunkArray,
  convertItemsToTilesChannel,
  convertItemsToTilesSeries,
} from "../formatters/ItemFormatter";

let cache = new Map();
const leftoverTiles = new Map();

export default function (filter: string) {
  return (pageIndex: number): Promise<any> => {
    const url = `/player_api.php?username=PIN2_Donato3&password=Maceraamico4&action=get_series`;

    if (cache.has(url)) {
      return cache.get(url);
    }

    let result = api._get(url).then((series) => {
      let results = series;
      let tiles = (
        leftoverTiles.has(filter) ? leftoverTiles.get(filter) : []
      ).concat(convertItemsToTilesSeries(results));
      let chunks = chunkArray(tiles);
      if (chunks[chunks.length - 1].length < 7) {
        leftoverTiles.set(filter, chunks.pop());
      } else {
        leftoverTiles.delete(filter);
      }
      return chunks;
    });

    cache.set(url, result);
    return result;
  };
}
