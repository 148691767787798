import { createSignal, createEffect, createMemo, onCleanup } from 'solid-js';
import { For } from 'solid-js';
import { Text, View, setActiveElement } from '@lightningjs/solid';
import { Row } from '@lightningjs/solid-ui';
import channelsProvider from "../api/providers/livetvChannels";
import { useParams } from "@solidjs/router";
import { playVideo, closeVideo } from "../pages/video";

const LiveTvPage = () => {
    const [osdView, setOsdView] = createSignal(true);
    const [channelItems, setChannelItems] = createSignal<any[]>([]);
    const [currentIndex, setCurrentIndex] = createSignal(0);

    const params = useParams();
    const channelProvider = createMemo(() => channelsProvider(params.filter || "all"));

    createEffect(async () => {
        try {
            const dataProvider = channelProvider();
            if (typeof dataProvider === 'function') {
                const data = await dataProvider(0);
                console.log("Data received:", data);
                console.log(data[2]);
                setChannelItems(data);
            }
        } catch (error) {
            console.error("Error fetching data from channelProvider:", error);
        }
    });

    // osd timeout
    createEffect(() => {
        let timeoutId;
        if (osdView()) {
            timeoutId = setTimeout(() => {
                setOsdView(false);
            }, 5000);
        }
        onCleanup(() => clearTimeout(timeoutId));
    });

    const playThisChannel = () => {
        if (osdView()) {
            console.log("play stream url = ", channelItems()[currentIndex()].stream_id);
            const video = playVideo();
            console.log(video);
            setActiveElement(video);
            //setBackdropAlpha(0.9);
        }
        else {
            //setOsdView(!osdView());
        }

        setOsdView(!osdView());

    };

    const updateIndex = (delta: number) => {
        console.log("===== ", currentIndex(), "   ", delta);
        const newIndex = currentIndex() + delta;
        if (newIndex >= 0 && newIndex < channelItems().length) {
            setCurrentIndex(newIndex);
        }
        if(!osdView()) setOsdView(true);
    };

    return (
        <View
            onDown={() => updateIndex(-1)}
            onUp={() => updateIndex(1)}
            onHide={() => setOsdView(!osdView())}
            onEnter={() => playThisChannel()}
        >
            {/* <video 
                src={channelItems().length > 0 ? channelItems()[currentIndex()].stream_id : ""} 
                autoPlay 
                controls 
                style={{ position: 'absolute', top: '10%', left: '10%', width: '80%', height: '40%' }}
            /> */}

            {osdView() && (
                <>
                    <Row
                        autofocus
                        gap={20}
                        selected={0}
                        x={150}
                        y={500}
                        transition={{ x: { duration: 350 } }}
                    >
                        <For each={channelItems()}>
                            {(item) => (
                                <View
                                    width={100}
                                    height={200}
                                    style={{ color: 0xff0000ff, focus: { color: 0xffffffff } }}
                                    src={"https://lumiere-a.akamaihd.net/v1/images/p_coco_19736_fd5fa537.jpeg"}
                                />
                            )}
                        </For>
                    </Row>

                    <View
                        //autofocus
                        x={0}
                        y={800}
                        width={1800}
                        height={200}
                        style={{ color: "0x808080", focus: { color: "grey" } }}
                    >
                        {/* {channelItems().length > 0 && (
                            <Text
                                src={channelItems()[currentIndex()].stream_icon}
                                alt="Stream Icon"
                                style={{ width: "50px", height: "50px", marginRight: "10px" }}
                            />
                        )} */}
                        <Text>
                            {channelItems().length > 0 ? `${channelItems()[currentIndex()].num} ${channelItems()[currentIndex()].name}` : "Loading..."}
                        </Text>
                    </View>
                </>
            )}

        </View>
    );
};

export default LiveTvPage;
