import {
    createEffect,
    createMemo,
    on,
    createSignal,
    Show,
    createSelector,
    For,
} from "solid-js";
import { ElementNode, IntrinsicTextNodeStyleProps, View, activeElement, hexColor } from "@lightningjs/solid";
import { Button, Column, Row } from "@lightningjs/solid-ui";
import { useNavigate, useParams } from "@solidjs/router";
import { TileRow, TileRowCategories, TileRowMovie } from "../../components";
import styles from "../../styles";
import { setGlobalBackground } from "../../state";
import seriesProvider from "../../api/providers/series";
import serieCategoriesProvider from "../../api/providers/categories_series";
import { createInfiniteScroll } from "../../components/pagination";
import ContentBlock from "../../components/ContentBlock";
import { assertTruthy } from "@lightningjs/renderer/utils";
import { debounce } from "@solid-primitives/scheduled";

const RowStyles: any = {
    display: "flex",
    justifyContent: "flexStart",
    width: 300,
    height: 300,
    color: hexColor("00000000"),
    gap: 26,
    y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const Series = () => {

    const params = useParams();
    const [columnY, setcolumnY] = createSignal(0);
    const [heroContent, setHeroContent] = createSignal({});
    const navigate = useNavigate();
    const isFirst = createSelector(() => {
        return 0;
    });

    const provider = createMemo(() => {
        return createInfiniteScroll(seriesProvider(params.filter || "all"));
    });

    const providerSeriesCategories = createMemo(() => {
        return createInfiniteScroll(serieCategoriesProvider(params.filter || "all"));
    });

    const delayedBackgrounds = debounce(
        (img: string) => setGlobalBackground(img),
        400
    );
    const delayedHero = debounce(
        (content: {}) => setHeroContent(content || {}),
        200
    );

    createEffect(
        on(
            activeElement,
            (elm: any) => {
                if (elm.backdrop) {
                    delayedBackgrounds(elm.backdrop);
                }

                if (elm.heroContent) {
                    delayedHero(elm.heroContent);
                }
            },
            { defer: true }
        )
    );

    function onRowFocus(this: any) {

        this.children.selected?.setFocus();
        setcolumnY((this.y || 0) * -1 + 24);
        let numPages = provider().pages().length;

        if (
            numPages === 0 ||
            (this.parent.selected && this.parent.selected >= numPages - 2)
        ) {
            provider().setPage((p) => p + 1);
        }

    }

    function onEnter(this: ElementNode) {
        let entity = this.children.selected;
        assertTruthy(entity && entity.href);
        navigate(entity.href);
        return true;
    }

    return (
        <Show when={provider().pages().length}>
            <ContentBlock y={180} x={162} content={heroContent()} />
            <View clipping>
                <Row x={160} gap={40} style={RowStyles}>
                    <Button states={"active"}>Comedy</Button>
                    <Button states={"active"}>Horror</Button>
                    <Button states={"active"}>Italian</Button>
                    <Button states={"active"}>Albanian</Button>
                    <Button states={"active"}>Drame</Button>
                </Row>
            </View>
            <View clipping style={styles.itemsContainer}>
                <Column
                    plinko
                    announce="Series"
                    y={columnY()}
                    scroll="none"
                    style={styles.Column}
                >
                    <For each={provider().pages()}>
                        {(items, i) => (
                            <TileRowMovie
                                autofocus={isFirst(i())}
                                items={items}
                                width={1620}
                                onFocus={onRowFocus}
                                onEnter={onEnter}
                            />
                        )}
                    </For>
                </Column>
            </View>
        </Show>
    );
};

export default Series;
