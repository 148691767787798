import { IntrinsicNodeProps, View, Text } from "@lightningjs/solid";
import { For, splitProps } from "solid-js";
import styles, { buttonStyles } from "../styles";
import { type Tile } from "../api/formatters/ItemFormatter";
import { Button, Column, Row } from "@lightningjs/solid-ui";

export function ThumbnailMovie(props: IntrinsicNodeProps) {
  return <View {...props} style={styles.Thumbnail} />;
}

export function Thumbnail(props: IntrinsicNodeProps) {
  return <View {...props} style={styles.ThumbnailChannel} />;
}

export function FocusRing(props: IntrinsicNodeProps) {
  return <View {...props} style={styles.FocusRing} />;
}

export interface TileRowProps extends IntrinsicNodeProps {
  items: Tile[];
}

export function TileRow(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others} style={styles.Row}>
      {/* @ts-ignore */}
      <For each={local.items}>{(item) => <Thumbnail {...item} />}</For>
    </Row>
  );
}

export function TileRowMovie(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others} style={styles.RowMovie}>
      {/* @ts-ignore */}
      <For each={local.items}>{(item) => <ThumbnailMovie {...item} />}</For>
    </Row>
  );
}

export function TileRowCategories(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others}>
      <For each={local.items}>{(item: any) =>
        <Button>{item.category_name}</Button>
      }
      </For>
    </Row>
  );

}

export function ButtonS(props) {
  return (
    <View {...props} forwardStates style={buttonStyles.container}>
      <Text style={buttonStyles.text}>{props.children}</Text>
    </View>
  );
}
